var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{attrs:{"label":"Code","dense":"","clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v(" fal fa-books-medical ")]),_c('v-select',{staticClass:"mt-n1",attrs:{"dense":"","items":[
          'DOI',
          'PMID',
          'PDR',
          'URL'
        ],"max-width":"60px","hide-details":"","flat":""},model:{value:(_vm.newReference.code_type),callback:function ($$v) {_vm.$set(_vm.newReference, "code_type", $$v)},expression:"newReference.code_type"}})]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-dialog',{attrs:{"width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","disabled":_vm.referenceSearch == '' || _vm.referenceSearch == null},on:{"click":_vm.getReference}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fal fa-plus-circle ")])],1)]}}]),model:{value:(_vm.newReferenceDialog),callback:function ($$v) {_vm.newReferenceDialog=$$v},expression:"newReferenceDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add reference")]),_c('v-card-text',{staticClass:"pt-2 pb-0"},[_c('reference-form',{ref:"referenceForm",attrs:{"info":"","code":_vm.referenceSearch,"code-type":_vm.newReference.code_type,"loading-code":_vm.referenceLoading,"readonly":_vm.referenceExist},on:{"update:code":function($event){_vm.referenceSearch=$event},"update:codeType":function($event){return _vm.$set(_vm.newReference, "code_type", $event)},"update:code-type":function($event){return _vm.$set(_vm.newReference, "code_type", $event)},"update:loadingCode":function($event){_vm.referenceLoading=$event},"update:loading-code":function($event){_vm.referenceLoading=$event},"update:readonly":function($event){_vm.referenceExist=$event},"loading":function($event){_vm.newReferenceLoading = $event},"update-reference":function($event){_vm.newReference = $event},"reference-created":() => {
                _vm.$root.$emit('add-references')
              }}}),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s((_vm.referenceExist == false) ? "This reference does not exist in the databases. Do you want to create it?" : _vm.newReference.title)+" ")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newReferenceDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","loading":_vm.newReferenceLoading,"disabled":_vm.referenceLoading},on:{"click":_vm.addReference}},[_vm._v(" "+_vm._s((_vm.referenceExist == false) ? 'Create' : 'Add')+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.referenceSearch),callback:function ($$v) {_vm.referenceSearch=$$v},expression:"referenceSearch"}}),_vm._l((_vm.references),function(item,index){return _c('v-card',{key:item.id,staticClass:"mx-auto mb-2",attrs:{"outlined":"","dense":""}},[_c('v-card-text',[_c('v-row',{staticClass:"mb-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"font-weight-black text-uppercase"},[_vm._v(" "+_vm._s(item.code_type + ": " +item.code)+" ")])]),_c('v-col',{attrs:{"md":"1"}},[_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.references.splice( index, 1 )}}},[_c('v-icon',{key:"fa-link-slash",attrs:{"color":"primary","xdark":"","x-small":""}},[_vm._v(" fal fa-link-slash ")])],1)],1)],1),_c('div',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('a',{staticClass:"text-caption",staticStyle:{"text-decoration":"none"},attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.url)+" ")])],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }